@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


:root {

  --cinza: darkgray;
  --cinzaClaro: #eaeaea;
  --cinzaEscuro: #6C6C6C;

  --escuro: #0000000b;

  --green: #27ae60;
  --darkgreen: #027936;
  --darkblue: #2980b9;
  --darkblue: #07507F;
  --red: #c0392b;
  --darkred: #720B00;
  --yellow: #f1c40f;
  --darkyellow: #947700;

  /* TEMA CLARO */
  --bk1: #ffffff;
  --bk2: #f5f5f5;
  --bk1T: #ffffffbf;
  --bk2T: #f5f5f5bf;
  --textM: #000000de;
  --textL: #0000008a;
  --textD: #00000061;
  --primaryM: #07507F;
  --primaryL: #2980b9;
  --primaryD: #022F4C;
  --primaryT: #07507Fbf;
  --textCP: #ffffff;
  --secondaryM: #616161;
  --secondaryL: #808080;
  --secondaryD: #434343;
  --secondaryT: #d2d2d2bf;
  --textCSe: #ffffff;
  --errorM: #f44336;
  --errorL: #e57373;
  --errorD: #d32f2f;
  --errorT: #f44336bf;
  --textCE: #ffffff;
  --warningM: #ff9800;
  --warningL: #ffb74d;
  --warningD: #f57c00;
  --warningT: #ff9800bf;
  --textCW: #000000de;
  --infoM: #2196f3;
  --infoL: #4DABF5;
  --infoD: #1769AA;
  --infoT: #2196f3bf;
  --textCI: #ffffff;
  --successM: #449e47;
  --successL: #69B16B;
  --successD: #2F6E31;
  --successT: #449e47bf;
  --textCSu: #ffffff;
  --divider: #0000001f;
  --shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

html,
body,
#root {
  font-size: 16px;
  height: 100%;
  width: 100%;
}

@media (max-width:1200px) {
  html,
  body,
  #root {
    font-size: 14px;
    height: 100%;
    width: 100%;
  }
}

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

b {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: var(--primaryL);
}

a:hover {
  text-decoration: underline;
}

:focus {
  outline: 0;
}