/*TEXT INPUTS*/

.input-group{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0.5rem;
}

.input-group label{
    position: absolute;
    top: 1.3rem;
    left: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
    color: var(--darkblue);
    transition: all 0.3s;
    pointer-events: none;
}

.input-group input:focus ~ label, .input-group input:valid ~ label, .input-group input:disabled ~ label{
    top: 0.3rem;
    left: 0.8rem;
    font-size: 0.6rem;
    font-weight: 500;
}

.input-group input{
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    padding: 1.2rem 0.8rem 0.8rem 0.8rem;
    border: none;
    border-bottom: 2px solid var(--cinza);
    color: var(--darkblue);
    background-color: transparent;
    transition: all 0.3s;
}
.input-group input:focus, .input-group input:valid{
    border-bottom: 2px solid var(--darkblue);
}
.input-group input:focus{
    background-color: var(--escuro);
}

.input-group input:disabled{
    font-weight: 300;
    color: var(--cinza);
}

.input-group svg{
    font-size: 1.5rem;
    margin-right: 0.8rem;
}