.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}

.title{
    font-size: 1.1rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--divider);
}

.subtitle{
    font-size: 1rem;
    font-weight: 300;
    color: var(--textL);
}

.form-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bandeiras{
    margin: 0.2rem;
    height: 25px;
}

.bandeira-img{
    pointer-events: none;
    position: absolute;
    right: 10px;
    height: 25px;
}

.ssl{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    margin-left: 1rem;
    border-left: 1px solid var(--divider);
}

@media (max-width: 770px) {
    .form-container{
        flex-wrap: wrap;
    }
    .ssl{
        margin-left: 0rem;
        border-left: none;
    }
}