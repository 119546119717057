.checkbox-container{
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 0.5rem;
}

.checkbox {
    position: relative;
    top: 1px;
    width: 1.1rem;
    height: 1.1rem;
    border: 1px solid var(--primaryM);
    border-radius: 3px;
    transition: background 0.1s ease;
    cursor: pointer;
    display: block;
}

.checkbox:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0.35rem;
    width: 0.2rem;
    height: 0.5rem;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid var(--textCP);
    border-bottom: 2px solid var(--textCP);
    transition: all 0.3s ease;
    transition-delay: 0.15s;
}

.lbl {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
}

.label {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    font-weight: 300;
    color: var(--textL);
}

input[type=checkbox]:checked~.checkbox {
    border-color: transparent;
    background: var(--primaryM);
    animation: jelly 0.6s ease;
}

input[type=checkbox]:checked~.checkbox:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
}

.cntr {
    position: relative;
}

@keyframes jelly {
    from {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(1.25, 0.75);
    }

    40% {
        transform: scale(0.75, 1.25);
    }

    50% {
        transform: scale(1.15, 0.85);
    }

    65% {
        transform: scale(0.95, 1.05);
    }

    75% {
        transform: scale(1.05, 0.95);
    }

    to {
        transform: scale(1, 1);
    }
}

.hidden-xs-up {
    display: none !important;
}