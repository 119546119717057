.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}

.title{
    font-size: 1.1rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--divider);
}

.subtitle{
    font-size: 1rem;
    font-weight: 300;
    color: var(--textL);
    margin-top: 1rem;
}

.pix-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.pix{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

.qr-code{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}
.qr-code-img{
    width: 150px;
}
.qr-code-txt{
    padding-left: 1rem;
    font-size: 0.7rem;
    font-weight: 400;
}

.btn-pix{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.txt-btn{
    display: flex;
    flex-direction: column;
}
.txt-btn span{
    margin-right: 0.5rem;
    font-size: 0.6rem;
    text-align: justify;
    text-align-last: left;
    word-break: break-all;
}

.ssl{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    margin-left: 1rem;
    border-left: 1px solid var(--divider);
}

@media (max-width: 770px) {
    .pix-container{
        flex-wrap: wrap;
    }
    .ssl{
        margin-left: 0rem;
        border-left: none;
    }
}