
.txt-status{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.txt-status svg{
    margin-right: 0.5rem;
}

.txt-status span{
    font-size: 1rem;
    font-weight: 400;
}
