.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
}

.title{
    font-size: 1.1rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--divider);
}

.subtitle{  
    font-size: 1rem;
    font-weight: 300;
    color: var(--textL);
    margin-top: 1rem;
}

.boleto-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.boleto{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
}

.boleto-img{
    width: 150px;
    margin: 1rem 0;
}
.boleto-img img{
    width: 150px;
}

.cod-boleto{
    font-size: 0.8rem;
    font-weight: 500;
}

.btns-boleto{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.ssl{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    margin-left: 1rem;
    border-left: 1px solid var(--divider);
}

@media (max-width: 770px) {
    .boleto-container{
        flex-wrap: wrap;
    }
    .ssl{
        margin-left: 0rem;
        border-left: none;
    }
}