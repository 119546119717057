
/*RADIO INPUTS*/

.h-radio{
    display: none;
}

.lbl-radio{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    border-radius: 0.3rem;
    margin: 0.5rem;
    font-size: 1rem;
    transition: all 0.3s;
    cursor: pointer;
}

input[type=radio]:checked + .lbl-radio{
    filter: brightness(110%);
}
input[type=radio]:disabled + .lbl-radio{
    opacity: 0.3;
    cursor: default;
}

.lbl-radio svg{
    margin-right: 0.5rem;
    font-size: 1.5rem;
}

.lbl-radio span{
    font-size: inherit;
    font-weight: 600;
}

/* CORES PADRÃO */

.primary + .lbl-radio{
    color: var(--textCP);
    background-color: var(--primaryD);
    transition: all 0.3s;
}
.primary + .lbl-radio:hover, .primary:checked + .lbl-radio{
    background-color: var(--primaryM);
}
.primary:disabled + .lbl-radio{
    background-color: var(--primaryL);
}

.secondary + .lbl-radio{
    color: var(--textCSe);
    background-color: var(--secondaryD);
    transition: all 0.3s;
}
.secondary + .lbl-radio:hover, .secondary:checked + .lbl-radio{
    background-color: var(--secondaryM);
}
.secondary:disabled + .lbl-radio{
    background-color: var(--secondaryL);
}

.error + .lbl-radio{
    color: var(--textCE);
    background-color: var(--errorD);
    transition: all 0.3s;
}
.error + .lbl-radio:hover, .error:checked + .lbl-radio{
    background-color: var(--errorM);
}
.error:disabled + .lbl-radio{
    background-color: var(--errorL);
}

.warning + .lbl-radio{
    color: var(--textCW);
    background-color: var(--warningD);
    transition: all 0.3s;
}
.warning + .lbl-radio:hover, .warning:checked + .lbl-radio{
    background-color: var(--warningM);
}
.warning:disabled + .lbl-radio{
    background-color: var(--warningL);
}

.info + .lbl-radio{
    color: var(--textCI);
    background-color: var(--infoD);
    transition: all 0.3s;
}
.info + .lbl-radio:hover, .info:checked + .lbl-radio{
    background-color: var(--infoM);
}
.info:disabled + .lbl-radio{
    background-color: var(--infoL);
}

.success + .lbl-radio{
    color: var(--textCSu);
    background-color: var(--successD);
    transition: all 0.3s;
}
.success + .lbl-radio:hover, .success:checked + .lbl-radio{
    background-color: var(--successM);
}
.success:disabled + .lbl-radio{
    background-color: var(--successL);
}

/* CORES GHOST */

.primary-g + .lbl-radio{
    color: var(--primaryM);
    border: 1px solid var(--primaryM);
    background-color: transparent;
    transition: all 0.3s;
}
.primary-g + .lbl-radio:hover, .primary-g:checked + .lbl-radio{
    color: var(--textCP);
    border: 1px solid var(--primaryM);
    background-color: var(--primaryM);
}
.primary-g:disabled + .lbl-radio{
    color: var(--primaryL);
    border: 1px solid var(--primaryL);
    background-color: transparent;
}

.secondary-g + .lbl-radio{
    color: var(--secondaryM);
    border: 1px solid var(--secondaryM);
    background-color: transparent;
    transition: all 0.3s;
}
.secondary-g + .lbl-radio:hover, .secondary-g:checked + .lbl-radio{
    color: var(--textCSe);
    border: 1px solid var(--secondaryM);
    background-color: var(--secondaryM);
}
.secondary-g:disabled + .lbl-radio{
    color: var(--secondaryL);
    border: 1px solid var(--secondaryL);
    background-color: transparent;
}

.error-g + .lbl-radio{
    color: var(--errorM);
    border: 1px solid var(--errorM);
    background-color: transparent;
    transition: all 0.3s;
}
.error-g + .lbl-radio:hover, .error-g:checked + .lbl-radio{
    color: var(--textCE);
    border: 1px solid var(--errorM);
    background-color: var(--errorM);
}
.error-g:disabled + .lbl-radio{
    color: var(--errorL);
    border: 1px solid var(--errorL);
    background-color: transparent;
}

.warning-g + .lbl-radio{
    color: var(--warningM);
    border: 1px solid var(--warningM);
    background-color: transparent;
    transition: all 0.3s;
}
.warning-g + .lbl-radio:hover, .warning-g:checked + .lbl-radio{
    color: var(--textCW);
    border: 1px solid var(--warningM);
    background-color: var(--warningM);
}
.warning-g:disabled + .lbl-radio{
    color: var(--warningL);
    border: 1px solid var(--warningL);
    background-color: transparent;
}

.info-g + .lbl-radio{
    color: var(--infoM);
    border: 1px solid var(--infoM);
    background-color: transparent;
    transition: all 0.3s;
}
.info-g + .lbl-radio:hover, .info-g:checked + .lbl-radio{
    color: var(--textCI);
    border: 1px solid var(--infoM);
    background-color: var(--infoM);
}
.info-g:disabled + .lbl-radio{
    color: var(--infoL);
    border: 1px solid var(--infoL);
    background-color: transparent;
}

.success-g + .lbl-radio{
    color: var(--successM);
    border: 1px solid var(--successM);
    background-color: transparent;
    transition: all 0.3s;
}
.success-g + .lbl-radio:hover, .success-g:checked + .lbl-radio{
    color: var(--textCSu);
    border: 1px solid var(--successM);
    background-color: var(--successM);
}
.success-g:disabled + .lbl-radio{
    color: var(--successL);
    border: 1px solid var(--successL);
    background-color: transparent;
}